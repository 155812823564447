<template>
<div class="col-md-8 mx-auto">
    <div class="card rounded shadow-lg">
      <div class="card-body">
        <div class="card-title text-center">
          <h1>{{ title }}</h1>
        </div>
        <br>
        <form v-on:submit.prevent="saveItem()">
          <!--
          <div class="row">
            <div class="col-md-6">
              <div class="form-group row">
                <label class="col-md-6 col-form-label">Compañía (*):</label>
                <input type="text" class="form-control col-md-6" v-model="item.cmp_id" disabled="true">
              </div>
            </div>
          </div>
        -->
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-6 col-form-label">Compañía (*):</label>
                <select v-model="item.cmp_id" class="form-control col-md-6" v-on:change="setCompania" required autofocus>
                  <option v-for="compania in companias" v-bind:value="compania._id">
                    {{ compania.cmp_nmbre }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-6 col-form-label">Clase de documento (*):</label>
                <select v-model="item.tcr_id" class="form-control col-md-6" required>
                  <option v-for="tpocor in tpocors" v-bind:value="tpocor._id">
                    {{ tpocor.tcr_nmbre }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-6 col-form-label">Año (*):</label>
                <input type="number" class="form-control col-md-6" v-model="item.dcm_ano" required>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-6 col-form-label">Consecutivo (*):</label>
                <input type="number" class="form-control col-md-6" v-model="item.dcm_cdgo" required>
              </div>
            </div>
          </div>
          <br />
          <div v-if="message">
            <p class="alert alert-warning" role="alert">{{ message }}</p>
          </div>
          <div class="row text-center">
            <div class="col-4">
              <button type="submit" class="btn btn-primary" v-on:click="setClose(true)">{{ buttontext }}</button>
            </div>
            <div class="col-4">
              <button type="text" class="btn btn-success" v-on:click="setClose(false)">Guardar</button>
            </div>
            <div class="col-4">
              <button type="text" class="btn btn-secondary" v-on:click="cancel()">Cancelar</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
  //import toastr from 'toastr';

  export default {
    data(){
      return{
        add: true, //Create a new document
        close: true, // Close the page and return to list
        title: '',
        item: {},
        message: '',
        buttontext: '',
        companias: [],
        tpocors: []
      }
    },
    computed: {
      company() {
        return this.$store.state.company
      },
      user() {
        return this.$store.state.user
      },
      cancelButtonVisibility() {
        if (this.addInd) {
          return 'visibility:hidden'
        } else {
          return 'visibility:visible'
        }
      }
    },
    created: function() {
      this.fetchCompania();

      this.add = this.$route.params.add;
      if (this.add){
        this.title = 'Crear Contadores';
        this.buttontext = 'Agregar Contadores';
      } else {
        this.title = 'Editar Contadores';
        this.buttontext = 'Actualizar Contadores';
        this.getItem(this.$route.params.id);
      }
    },
    methods: {
      setClose(close){
        this.close = close;
      },
      fetchCompania()
      {
        let uri = '/cmps';
        this.axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('jwtToken');
        this.axios.get(uri).then((response) => {
          this.companias = response.data;
        });
      },
      setCompania(e)
      {
        var cmp = e.target.value;

        var uri = "/tpocors/" + cmp;
        this.axios.get(uri)
        .then((response) => {
          this.tpocors = response.data;
        })
        .catch((err) => {
          alert('No existen items para mostrar.')
        });
      },
      fetchTpocor(){
        let uri = '/tpocors/' + this.$store.state.company;
        this.axios.get(uri).then((response) => {
          var list = response.data;
          list.sort(function(a,b){
            if (a.tcr_nmbre > b.tcr_nmbre) { return 1; };
            if (a.tcr_nmbre < b.tcr_nmbre) { return -1; };
            return 0;
          });
          this.tpocors = list;
        });
      },
      saveItem(){
        if (this.add){
          let uri = '/counters/add';
          //alert(this.item);
          this.axios.post(uri, this.item)
          .then(response => {
            this.$router.replace({ name: 'DisplayCounter' });
            this.message = 'Registro guardado';
          })
          .catch(err => {
            this.message = '¡Error al crear el registro! ' + err;
          });
        }
        else {
          let uri = '/counters/update/' + this.item._id;
          this.axios.post(uri, this.item).then((response) => {
            if (this.close){
              this.$router.push({name: 'DisplayCounter'});
            }
            this.message = 'Registro guardado';
          });
        }
      },
      getItem(id){
        let uri = '/counters/edit/' + id;
        this.axios.get(uri).then((response) => {
          this.item = response.data;

          let uri = '/tpocors/' + this.item.cmp_id;
          this.axios.get(uri).then((response) => {
            var list = response.data;
            list.sort(function(a,b){
              if (a.tcr_nmbre > b.tcr_nmbre) { return 1; };
              if (a.tcr_nmbre < b.tcr_nmbre) { return -1; };
              return 0;
            });
            this.tpocors = list;
          });
        });
      },
      cancel(){
        this.$router.replace({name: 'DisplayCounter'});
      }
    }
  }
  </script>

  <style>
    .warning {
      color: #ff0000;
    }
  </style>
